"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const helpers_1 = require("./helpers");
function screens(input) {
    if (!input) {
        return {};
    }
    const screenData = Object.entries(input).reduce((acc, [screen, value]) => {
        const data = [0, Infinity, 0];
        const values = typeof value === `string` ? { min: value } : value;
        const minPx = values.min ? (0, helpers_1.toPx)(values.min) : 0;
        if (minPx === null) {
            (0, helpers_1.warn)(`invalid screen config value: ${screen}->min: ${values.min}`);
        }
        else {
            data[0] = minPx;
        }
        const maxPx = values.max ? (0, helpers_1.toPx)(values.max) : Infinity;
        if (maxPx === null) {
            (0, helpers_1.warn)(`invalid screen config value: ${screen}->max: ${values.max}`);
        }
        else {
            data[1] = maxPx;
        }
        acc[screen] = data;
        return acc;
    }, {});
    const values = Object.values(screenData);
    values.sort((a, b) => {
        const [minA, maxA] = a;
        const [minB, maxB] = b;
        if (maxA === Infinity || maxB === Infinity) {
            return minA - minB;
        }
        return maxA - maxB;
    });
    let order = 0;
    values.forEach((value) => (value[2] = order++));
    return screenData;
}
exports.default = screens;
