"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../types");
const helpers_1 = require("../helpers");
function fontSize(value, config, context = {}) {
    const configValue = config === null || config === void 0 ? void 0 : config[value];
    if (!configValue) {
        return (0, helpers_1.unconfiggedStyle)(`fontSize`, value, context);
    }
    if (typeof configValue === `string`) {
        return (0, helpers_1.getCompleteStyle)(`fontSize`, configValue);
    }
    let style = {};
    const [fontSize, rest] = configValue;
    const fontSizeStyle = (0, helpers_1.getStyle)(`fontSize`, fontSize);
    if (fontSizeStyle) {
        style = fontSizeStyle;
    }
    if (typeof rest === `string`) {
        return (0, helpers_1.complete)((0, helpers_1.mergeStyle)(`lineHeight`, calculateLineHeight(rest, style), style));
    }
    const { lineHeight, letterSpacing } = rest;
    if (lineHeight) {
        (0, helpers_1.mergeStyle)(`lineHeight`, calculateLineHeight(lineHeight, style), style);
    }
    if (letterSpacing) {
        (0, helpers_1.mergeStyle)(`letterSpacing`, letterSpacing, style);
    }
    return (0, helpers_1.complete)(style);
}
exports.default = fontSize;
// calculates line-height for relative units
function calculateLineHeight(lineHeight, style) {
    const parsed = (0, helpers_1.parseNumericValue)(lineHeight);
    if (parsed) {
        const [number, unit] = parsed;
        if ((unit === types_1.Unit.none || unit === types_1.Unit.em) && typeof style.fontSize === `number`) {
            return style.fontSize * number;
        }
    }
    return lineHeight;
}
