"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddedUtilities = void 0;
const plugin = (handler) => {
    return { handler, config: undefined };
};
exports.default = plugin;
function getAddedUtilities(plugins) {
    var _a;
    return ((_a = plugins === null || plugins === void 0 ? void 0 : plugins.reduce((utils, plugin) => ({ ...utils, ...callPluginFunction(plugin.handler) }), {})) !== null && _a !== void 0 ? _a : {});
}
exports.getAddedUtilities = getAddedUtilities;
function callPluginFunction(pluginFn) {
    let added = {};
    pluginFn({
        addUtilities: (utilities) => {
            added = utilities;
        },
        ...core,
    });
    return added;
}
function notImplemented(fn) {
    throw new Error(`tailwindcss plugin function argument object prop "${fn}" not implemented`);
}
const core = {
    addComponents: notImplemented,
    addBase: notImplemented,
    addVariant: notImplemented,
    e: notImplemented,
    prefix: notImplemented,
    theme: notImplemented,
    variants: notImplemented,
    config: notImplemented,
    corePlugins: notImplemented,
    matchUtilities: notImplemented,
    postcss: null,
};
