"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppColorScheme = exports.useDeviceContext = void 0;
const react_1 = require("react");
const react_native_1 = require("react-native");
function useDeviceContext(tw, opts = { withDeviceColorScheme: true }) {
    const window = (0, react_native_1.useWindowDimensions)();
    tw.setWindowDimensions(window);
    tw.setFontScale(window.fontScale);
    tw.setPixelDensity(window.scale === 1 ? 1 : 2);
    const colorScheme = (0, react_native_1.useColorScheme)();
    if (opts.withDeviceColorScheme) {
        tw.setColorScheme(colorScheme);
    }
}
exports.useDeviceContext = useDeviceContext;
function useAppColorScheme(tw, initialValue) {
    const [colorScheme, setColorScheme] = (0, react_1.useState)(initialValue !== null && initialValue !== void 0 ? initialValue : react_native_1.Appearance.getColorScheme());
    return [
        colorScheme,
        () => {
            const toggled = colorScheme === `dark` ? `light` : `dark`;
            tw.setColorScheme(toggled);
            setColorScheme(toggled);
        },
        (newColorScheme) => {
            tw.setColorScheme(newColorScheme);
            setColorScheme(newColorScheme);
        },
    ];
}
exports.useAppColorScheme = useAppColorScheme;
